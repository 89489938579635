import React from "react";
import "./OurCollection.scss";
const OurCollection = () => {
  return (
    <div className="collection-container section">
      <div className="collection-box collection-box1">
        <h2>Redefining Standards with Exclusive and Stylish Jewellery.</h2>
      </div>
      <div className="collection-box collection-box2">
        <p>
          Explore the captivating world of unique Persian carpets at our
          gallery. Discover individual pieces seamlessly blending with upscale
          decor, adding a special touch to every room. Let our curated
          collection inspire and elevate your space with the perfect blend of
          modern furniture classics and exclusive carpet designs.
        </p>
      </div>
    </div>
  );
};

export default OurCollection;
