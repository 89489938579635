import React from 'react'
import Contact from '../../Components/Contact/Contact'

const ContactPageComponent = () => {
  return (
    <div>
      <Contact/>
    </div>
  )
}

export default ContactPageComponent;
