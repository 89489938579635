import React, { useState } from "react";
import "./Banner.scss";
import play from "../../asset/New Sunity Jweller’s_Image/Group 84.webp";
import img from "../../asset/New Sunity Jweller’s_Image/Group 1000001250.webp";
import VideoContainer from "../VideoContainer/VideoContainer";


const Banner = () => {
  const [video, setVideo] = useState(false);
 
  const PlayVideo = () => {
    setVideo(true)
  };
  return (
    <>
      {video ? (
        <VideoContainer setVideo={setVideo} />
      ) : (
        <div className="banner section">
          <img className="banner-img" src={img} alt="" />
          <div className="banner-container">
            <img
              src={play}
              alt="Model Wearing Exclusive Jewelry from New Sunity Jewellers"
              onClick={PlayVideo}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
