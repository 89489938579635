const CartReducer = (state, action) => {
  if (action.type === "ADD_TO_CART") {
    let item = action.payload;

    return {
      ...state,
      cart: [...state.cart, item],
    };
  }
  if (action.type === "REMOVE_ITEM") {
    let id = action.payload;
    let updatedCart = state.cart.filter((item) => item._id !== id);

    return {
      ...state,
      cart: updatedCart,
    };
  }
  if (action.type === "CLEARCART") {
    return {
      ...state,
      cart: [],
    };
  }

  return state;
};

export default CartReducer;
