import React from "react";
import "./GalleryPageComponent.scss";
import GalleryComponent from "../../Components/GalleryPageComponent/GalleryComponent";
import PageTopBanner from "../../Components/PageTopBanner/PageTopBanner";


const GalleryPageComponent = () => {
  return (
    <div>
      
      <PageTopBanner heading={"View our gallery"} />
      <div className="gallery-main-container">
        <div className="gallery-heading-container">
          <h1>Gallery</h1>
          <p>
            Step into the captivating allure of our gallery at New Sunity
            Jewellers. Each display unveils a symphony of exquisite
            craftsmanship and timeless beauty. Discover the perfect union of
            artistry and luxury, where every piece reflects a celebration of
            individuality. Explore the extraordinary at New Sunity Jewellers'
            gallery.
          </p>
        </div>
        <GalleryComponent />
      </div>
    </div>
  );
};

export default GalleryPageComponent;
