import React from 'react'
import Booking from "../PagesComponent/Booking/Booking"

const BookingPage = () => {
  return (
    <div>
      <Booking/>
    </div>
  )
}

export default BookingPage
