import React from 'react'
import ContactPageComponent from '../PagesComponent/Contact/ContactPageComponent'
import { Helmet } from 'react-helmet'
const ContactPage = () => {
  return (
    <div>
          <Helmet>
        <title>
          Contact New Sunity Jewellers - Reach Out for Inquiries and Assistance
        </title>
        <meta
          name="description"
          content="Contact New Sunity Jewellers for inquiries, assistance, or to explore our exquisite jewelry collection. Reach us for the finest customer service in Karimpur, Kolkata, Krishnanagar, and Nadia."
        />
        <meta
          name="keywords"
          content="New Sunity Jewellers, Contact Us, Jewelry Store Contact, Customer Service, Inquiries, Assistance"
        />
      </Helmet>
      <div style={{ display: "none" }}>
        <h1>Holmark Jewelry Showcase - New Sunity Jewellers in Karimpur</h1>
        <h1>Discover Unmatched Brilliance at New Sunity Jewellers, Karimpur</h1>
        <h1>
          Unveil Your Elegance - Holmark Collection at New Sunity Jewellers
        </h1>
        <h1>New Sunity Jewellers - Your Journey to Radiance in Karimpur</h1>
        <h1>
          Holmark Jewelry Brilliance - Exclusively at New Sunity Jewellers
        </h1>
        <h1>
          New Sunity Jewellers - Elevating Your Jewelry Experience in Karimpur
        </h1>
        <h1>Step into Opulence - Holmark Jewelry at New Sunity Jewellers</h1>
        <h1>New Sunity Jewellers - Your Destination for Timeless Elegance</h1>
        <h1>Holmark Jewelry Unveiled - Visit New Sunity Jewellers, Karimpur</h1>
        <h1>
          Discover Karimpur's Finest - New Sunity Jewellers' Holmark Collection
        </h1>
        {/* <!-- Internal Link → */}
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/product"
          target="_blank"
        >
          Product Page
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/about"
          target="_blank"
        >
          About Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/contact"
          target="_blank"
        >
          Contact Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/gallery"
          target="_blank"
        >
          View Gallery
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/cart"
          target="_blank"
        >
          View Cart
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/"
          target="_blank"
        >
          Home
        </a>
        {/* <!-- External Links → */}
        <a
          rel="noopener noreferrer"
          href="https://aspireworldtechsolutions.com/"
          target="_blank"
        >
          Aspire World Tech Solutions
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.energiccropscience.com/"
          target="_blank"
        >
          Energic Crop Science
        </a>
        <a
          rel="noopener noreferrer"
          href="https://sudhirbastralaya.in/"
          target="_blank"
        >
          Sudhir Astralaya
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.sunitijewellers.com/"
          target="_blank"
        >
          Suniti Jewellers
        </a>
      </div>

      <ContactPageComponent/>
    </div>
  )
}

export default ContactPage
