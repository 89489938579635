import React from 'react'
import PageNotFound from "../PagesComponent/PageNotFound/PageNotFound"
const PageNotFoundPage = () => {
  return (
    <div>
      <PageNotFound/>
    </div>
  )
}

export default PageNotFoundPage
