import React from 'react'
import "./ProductDetails.scss"

const ProductDetails = () => {
  return (
    <div className=''>
      ProductDetails
    </div>
  )
}

export default ProductDetails
