import React from 'react'
import Product from "../PagesComponent/Product/Product"
import PageTopBanner from '../Components/PageTopBanner/PageTopBanner'
import { Helmet } from 'react-helmet'
const ProductPage = () => {
  return (
    <div>
          <Helmet>
        <title>New Sunity Jewellers - Finest Jewellery Collection</title>
        <meta
          name="description"
          content="Explore the finest gold necklace collection at New Sunity Jewellers. Find exclusive designs crafted with precision and elegance."
        />
        <meta
          name="keywords"
          content="New Sunity Jewellers, Gold Necklace, Jewellery, Exquisite Jewellery, Fine Jewellery"
        />
      </Helmet>
      <div style={{ display: "none" }}>
        <h1>Step into Luxury - New Sunity Jewellers in Karimpur</h1>
        <h1>New Sunity Jewellers - Your Gateway to Timeless Elegance</h1>
        <h1>Sparkle with Holmark - New Sunity Jewellers, Karimpur</h1>
        <h1>New Sunity Jewellers - Elevate Your Style with Holmark Jewelry</h1>
        <h1>
          Explore the Finest Craftsmanship at New Sunity Jewellers, Karimpur
        </h1>
        <h1>Holmark Jewelry - A Statement of Style at New Sunity Jewellers</h1>
        <h1>New Sunity Jewellers - Redefining Luxury in Karimpur</h1>
        <h1>
          Find Your Perfect Piece at New Sunity Jewellers - Karimpur's Gem
        </h1>
        <h1>
          Experience Holmark - Exclusive Jewelry Line at New Sunity Jewellers
        </h1>
        <h1>New Sunity Jewellers - Karimpur's Choice for Timeless Beauty</h1>

        {/* Internal Link  */}
        <a rel="noopener noreferrer" href="https://newsunityjewellers.in/product" target="_blank">
          Product Page
        </a>
        <a rel="noopener noreferrer" href="https://newsunityjewellers.in/about" target="_blank">
          About Us
        </a>
        <a rel="noopener noreferrer" href="https://newsunityjewellers.in/contact" target="_blank">
          Contact Us
        </a>
        <a rel="noopener noreferrer" href="https://newsunityjewellers.in/gallery" target="_blank">
          View Gallery
        </a>
        <a rel="noopener noreferrer" href="https://newsunityjewellers.in/cart" target="_blank">
          View Cart
        </a>
        <a rel="noopener noreferrer" href="https://newsunityjewellers.in/" target="_blank">
          Home
        </a>
        {/* External Links  */}
        <a rel="noopener noreferrer" href="https://aspireworldtechsolutions.com/" target="_blank">
          Aspire World Tech Solutions
        </a>
        <a rel="noopener noreferrer" href="https://www.energiccropscience.com/" target="_blank">
          Energic Crop Science
        </a>
        <a rel="noopener noreferrer" href="https://sudhirbastralaya.in/" target="_blank">
          Sudhir Astralaya
        </a>
        <a rel="noopener noreferrer" href="https://www.sunitijewellers.com/" target="_blank">
          Suniti Jewellers
        </a>
      </div>

    <PageTopBanner heading={"our products"}/>
      <Product/>
    </div>
  )
}

export default ProductPage
