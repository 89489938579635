import { Helmet } from "react-helmet";
import Home from "../PagesComponent/Home/Home";
import { useEffect, useState } from "react";
import { UsePrice } from "../utility/constant";

function HomePage() {
  const [prices, setPrices] = useState([]);
  const getPrice = () => {
    const temps = UsePrice()
      .then((response) => {
        setPrices(response.data.data);
        // console.log("response_data_prices:", response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };
  useEffect(() => {
    getPrice();
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Gold- Rs. {`${prices.length !== 0 && prices.gold_24_k_price}`} &
          Silver - Rs. {`${prices.length !== 0 && prices.silver_1_kg}`} Prices
          Today in Karimpur | New Sunity Jewellers
        </title>
        <meta
          name="description"
          content="Check today's gold and silver prices in Karimpur at New Sunity Jewellers. Find the latest rates for gold and silver jewelry and investment."
        />
        <meta
          name="keywords"
          content="Gold price, Silver price, Today's gold rate, Today's silver rate, Karimpur gold price, Karimpur silver price, New Sunity Jewellers"
        />
      </Helmet>
      <div style={{ display: "none" }}>
        <h1>New Sunity Jewellers - Karimpur's Finest Jewelry Collection</h1>
        <h1>Explore Exquisite Holmark Jewelry at New Sunity Jewellers</h1>
        <h1>Karimpur's Top Jewelry Shop - New Sunity Jewellers</h1>
        <h1>
          Discover the Elegance of Holmark Jewelry at New Sunity Jewellers
        </h1>
        <h1>Premium Gold & Silver Jewelry at New Sunity Jewellers, Karimpur</h1>
        <h1>
          New Sunity Jewellers - Your Destination for Luxury Jewelry in Karimpur
        </h1>
        <h1>
          Holmark Jewelry Products - Exclusive Collection at New Sunity
          Jewellers
        </h1>
        <h1>Unveiling Karimpur's Best-Kept Secret - New Sunity Jewellers</h1>
        <h1>New Sunity Jewellers - Where Tradition Meets Modernity</h1>
        <h1>Holmark Jewelry Extravaganza at New Sunity Jewellers, Karimpur</h1>
        {/* Internal Link */}
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/product"
          target="_blank"
        >
          Product Page
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/about"
          target="_blank"
        >
          About Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/contact"
          target="_blank"
        >
          Contact Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/gallery"
          target="_blank"
        >
          View Gallery
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/cart"
          target="_blank"
        >
          View Cart
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/"
          target="_blank"
        >
          Home
        </a>
        {/*  External Links */}
        <a
          rel="noopener noreferrer"
          href="https://aspireworldtechsolutions.com/"
          target="_blank"
        >
          Aspire World Tech Solutions
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.energiccropscience.com/"
          target="_blank"
        >
          Energic Crop Science
        </a>
        <a
          rel="noopener noreferrer"
          href="https://sudhirbastralaya.in/"
          target="_blank"
        >
          Sudhir Astralaya
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.sunitijewellers.com/"
          target="_blank"
        >
          Suniti Jewellers
        </a>
      </div>
      <Home />
    </div>
  );
}

export default HomePage;
