import React from "react";
import "./Morebutton.scss";
import { IoMdArrowDropright } from "react-icons/io";



const Morebutton = ({more}) => {
  return (
    <div className="more-container">
      <p className="more">
        {more} <IoMdArrowDropright className="arrow" />
      </p>
      <div className="dash"></div>
    </div>
  );
};

export default Morebutton;
