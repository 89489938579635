import React from "react";
import "./CartComponent.scss";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";
import CartProduct from "../CartProduct/CartProduct";

const CartComponent = () => {
  const RemoveCartItem = () => {
    clearCart();
  };

  const { cart, clearCart } = useCartContext();
  return (
    <div className="cart-container">
      <h1 className="heading">Your Cart</h1>
      {cart.length !== 0 && <div className="cart-item-clear">
        <p className="cart-item-number">Cart Items - {cart.length}</p>
        <p className="cart-item-number clear-cart-btn" onClick={RemoveCartItem}>Clear Cart</p>
      </div>}
      {cart.length === 0 && (
        <div className={cart.length === 0 ? "empty" : ""}>
          <h4 className="empty-heading">
            Your Cart is Empty! Add Item To The Cart
          </h4>
          <Link to={"/product"}>
            <button className="go-home">Go Product</button>
          </Link>
        </div>
      )}
      <div className="cart-product">
        {cart.map((item, index) => {
          return <CartProduct item={item} key={item._id} index={index} />;
        })}
      </div>
      
    </div>
  );
};

export default CartComponent;
