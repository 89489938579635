import React from 'react'
import Cart from "../PagesComponent/Cart/Cart"
const CartPage = () => {
  return (
    <div>
      <Cart/>
    </div>
  )
}

export default CartPage
