import React from "react";
import "./AboutCompany.scss";
import img1 from "../../asset/New Sunity Jweller’s_Image/web1.webp";
import img2 from "../../asset/New Sunity Jweller’s_Image/web2.webp";
import Morebutton from "../MoreButton/Morebutton";


const AboutCompany = () => {
  return (
    <div className="about-container section" id="about">
      <div className="about">
        <div className="about-text">
          <div className="about-us-container">
            <p className="about-us">About Us</p>
            <div className="dash"></div>
          </div>
          <h1>Where Exclusivity Meets Elegance in Our Showroom Haven</h1>
          <p>
            Welcome to New Sunity Jewellers, where every piece of jewelry
            narrates a story of timeless elegance and unparalleled
            craftsmanship. At New Sunity, we blend tradition with innovation,
            offering a curated collection that transcends trends. Our commitment
            to quality and a customer-centric approach defines us. Explore a
            world where every gem is a symbol of sophistication and every visit
            is an enchanting experience. Discover the essence of luxury and
            individuality at New Sunity Jewellers – where your journey to
            adornment begins.
          </p>
          <div className="learn--container">
            <a href={"#gallery"}>
              <Morebutton more={"View more"} />
            </a>
          </div>
        </div>
        <div className="about-image-container">
          <div className="images">
            <div className="tow-img">
              <img
                src={img1}
                alt="Kolkata Gemstone Necklaces from New Sunity Jewellers"
                className="one"
              />
              <img
                src={img2}
                alt="Nadia Jewelry Shop owner at New Sunity Jewellers"
                className="tow"
              />
            </div>
            {/* <img src={img} alt="" /> */}
            <div className="map">
              <iframe
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1822.7018735744111!2d88.6252812!3d23.9815158!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f95a9ad0ab5e71%3A0xcb5751bf99cd56a4!2sNew%20Sunity%20Jewellers!5e0!3m2!1sen!2sin!4v1707937111253!5m2!1sen!2sin"
                style={{ border: 0 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="google map"
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
          <div className="about-rotat">
            <h1>About Showroom</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
