import React from "react";
import About from "../PagesComponent/About/About";
import { Helmet } from "react-helmet";

function AboutPage() {
  return (
    <>
      <Helmet>
        <title>
          About New Sunity Jewellers - Discover Our Story and Commitment to
          Excellence
        </title>
        <meta
          name="description"
          content="Learn about the history and values of New Sunity Jewellers. Discover our commitment to providing the finest jewelry in Karimpur, Kolkata, Krishnanagar, and Nadia."
        />
        <meta
          name="keywords"
          content="New Sunity Jewellers, About Us, Our Story, Jewelry Store, Fine Jewelry, Company Values"
        />
        <meta
          name="keywords"
          content="New Sunity Jewellers, About Us, Our Story, Jewelry Craftsmanship, Customer Service"
        />
      </Helmet>

      <div style={{ display: "none" }}>
        <h1>New Sunity Jewellers - A Symphony of Elegance in Karimpur</h1>
        <h1>Adorn Yourself with Holmark - New Sunity Jewellers, Karimpur</h1>
        <h1>
          Unlock Luxury at New Sunity Jewellers - Holmark Jewelry Extravaganza
        </h1>
        <h1>
          New Sunity Jewellers - Your Gateway to Holmark's Sparkling Creations
        </h1>
        <h1>
          Step into Splendor - Holmark Jewelry at New Sunity Jewellers, Karimpur
        </h1>
        <h1>New Sunity Jewellers - Karimpur's Jewel Haven</h1>
        <h1>Elegance Redefined - Holmark Collection at New Sunity Jewellers</h1>
        <h1>Explore Holmark's Finest at New Sunity Jewellers, Karimpur</h1>
        <h1>New Sunity Jewellers - Your Signature of Style in Karimpur</h1>
        <h1>Shine Bright with Holmark - Available at New Sunity Jewellers</h1>
        {/* <!-- Internal Link → */}
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/product"
          target="_blank"
        >
          Product Page
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/about"
          target="_blank"
        >
          About Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/contact"
          target="_blank"
        >
          Contact Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/gallery"
          target="_blank"
        >
          View Gallery
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/cart"
          target="_blank"
        >
          View Cart
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/"
          target="_blank"
        >
          Home
        </a>
        {/* <!-- External Links → */}
        <a
          rel="noopener noreferrer"
          href="https://aspireworldtechsolutions.com/"
          target="_blank"
        >
          Aspire World Tech Solutions
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.energiccropscience.com/"
          target="_blank"
        >
          Energic Crop Science
        </a>
        <a
          rel="noopener noreferrer"
          href="https://sudhirbastralaya.in/"
          target="_blank"
        >
          Sudhir Astralaya
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.sunitijewellers.com/"
          target="_blank"
        >
          Suniti Jewellers
        </a>
      </div>

      <About />
    </>
  );
}

export default AboutPage;
