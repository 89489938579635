import React from "react";
import "./SaleAndSpace.scss";
import image from "../../asset/New Sunity Jweller’s_Image/webimg.webp";
import image1 from "../../asset/New Sunity Jweller’s_Image/Image-1.webp";
import image2 from "../../asset/New Sunity Jweller’s_Image/Image-2.webp";
import MoreButton from "../MoreButton/Morebutton";

const SaleAndSpace = () => {
  return (
    <div className="Sale-Container section">
      <div className="Space-text">
        <div className="the-text">
          <p>The</p>
        </div>
        <div className="sale-text">
          <h3>Sale & Space</h3>
        </div>
      </div>
      <div className="image-container">
        <div className="left-container">
          <div className="image-box">
            <img src={image} alt="Kolkata Gold Earrings Showcase at New Sunity Jewellers" />
            <p>
              Embark on a journey into the enchanting realm of exclusive Persian
              carpets with us. Our collection showcases only individual
              masterpieces, ensuring a unique touch that perfectly complements
              your space
            </p>
          </div>
          <div className="learn--container">
            <a href="#about">
              <MoreButton more={"Learn more"} />
            </a>
          </div>
        </div>
        <div className="right-container">
          <div className="image-box1">
            <div className="image-inner-box">
              <img src={image1} alt="Diamond Jewelry Close-up Shots by New Sunity Jewellers" />
              <p>
                Embark on a journey into the enchanting realm of exclusive
                Persian carpets with us. Our collection showcases only
                individual masterpieces, ensuring a unique touch that perfectly
                complements your space
              </p>
            </div>
            <div className="rotat-pra">
              <p>Design Carpet</p>
            </div>
          </div>
          <div className="image-box2">
            <img src={image2} alt="Exquisite Gold Bangles Showcase by New Sunity Jewellers" />
            <h4>Design carpet</h4>
            <p>
              Embark on a journey into the enchanting realm of exclusive Persian
              carpets with us. Our collection showcases only individual
              masterpieces, ensuring a unique touch that perfectly complements
              your space
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleAndSpace;
