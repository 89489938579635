import React from "react";
import { MdOutlineShoppingCart,MdDeleteForever } from "react-icons/md";
// import "./CartProduct.scss";
import "../ProductCard/ProductCard.scss"
import { Link } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";


const CartProduct = ({ item }) => {
    const { RemoveCartItem } = useCartContext();
    const removeCartItems = (_id) => {
      RemoveCartItem(_id);
    };
  
  // console.log(item);

  const { name, description, _id, image ,weight  } = item;
  return (
    <div className="product-card" key={_id}>
      {/* <img src={image?.location } alt={name} /> */}
      <img src={image} alt={name} />
      <div className="details">
        <h3 className="product-heading">{name}</h3>
        <p className="product-sub">{description}</p>
        {/* <p>{weight} gm</p> */}
        <div className="btn">
          <Link to={"/booking/" + _id}><MdOutlineShoppingCart className="book-now button"/></Link>
          <MdDeleteForever className=" delet button" onClick={() => removeCartItems(_id)} />
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
