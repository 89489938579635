import "./Products.scss";
import ProductCard from "../ProductCard/ProductCard";
import Morebutton from "../MoreButton/Morebutton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import {
  CategorieData,
  eightProduct,
  ProductsData,
} from "../../utility/constant";
// import axios from "axios";

const Products = () => {
  const [categoryDetails, setCategoryDetails] = useState([]);
  // const [categoryName, setCategoryName] = useState(" ");
  const [categoryName, setCategoryName] = useState("all");
  const [products, setProducts] = useState(eightProduct);
  const [productsStore, setProductsStore] = useState(ProductsData);
  // const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(CategorieData);
  const [page, setpage] = useState(1);

  const selectCatrgory = (item) => {
    setCategoryName(item.name);
    setCategoryDetails([item.id]);
  };
  const selectCatrgoryAll = () => {
    setCategoryDetails([]);
    setCategoryName("all");
  };

  // const getCategory = () => {
  //   const cate = UseCategorys();
  //   cate
  //     .then((response) => {
  //       setCategory(response?.data?.data);
  //       // console.log("response_data_products:", response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // };

  // const getProducts = () => {
  //   const temps = UseProduct(page, categoryDetails)
  //     .then((response) => {
  //       setProducts(response?.data);
  //       // console.log("response_data_products:", response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // };
  // useEffect(() => {
  //   getProducts();
  //   getCategory();
  // }, [page, categoryDetails]);


  useEffect(() => {
    if (categoryName !== "all") {
      const finterProductData = productsStore.filter(
        (item) => item.category === categoryName
      );
      setProducts(finterProductData);
      // console.log(finterProductData);
    }
    else if (categoryName === "all"){
      setProducts(eightProduct);
    }
  }, [categoryName]);

  return (
    <div className="all-product-container section" id="products">
      <div className="heading-container">
        <h1 className="heading">Trending Product</h1>
        <div className="catagories">
          <div className="flex">
          {/* for api  */}
            {/* <li
              className={categoryName === "all" && "active"}
              onClick={selectCatrgoryAll}
            >
              All
            </li> */}
            {category.length !== 0 &&
              category.map((item, index) => {
                return (
                  <li
                    className={categoryName === item.name ? "active" : " "}
                    onClick={() => selectCatrgory(item)}
                    key={index}
                  >
                    {item.name}
                    {/* {item} */}
                  </li>
                );
              })}
          </div>
        </div>
      </div>

      {products?.length !== 0 && (
        <div className="product-container">
          {products.map((item, index) => {
            return <ProductCard item={item} key={index} />;
          })}
          {/* {products?.product?.length !== 0 ? (
            products.product.map((item) => {
              return <ProductCard item={item} key={item._id} />;
            })
          ) : (
            <div className="no-product-container">
              <Loader />
              <p className="no-product">
                No product here! Choose another category
              </p>
            </div>
          )} */}
        </div>
      )}
      <Link to={"/product"}>
        <Morebutton more={"View more"} />
      </Link>
    </div>
  );
};

export default Products;
