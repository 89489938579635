import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBPMyA7_1ODz9qDjcthqUvGcuKbuQnu7x8",
  authDomain: "aspireworldtechsolutions-4a61b.firebaseapp.com",
  projectId: "aspireworldtechsolutions-4a61b",
  storageBucket: "aspireworldtechsolutions-4a61b.appspot.com",
  messagingSenderId: "916982780056",
  appId: "1:916982780056:web:11cbed4cdcce0ca40680ed",
  measurementId: "G-TB8Q1XK941",
  databaseURL: "https://aspireworldtechsolutions-4a61b-default-rtdb.firebaseio.com/",
};
export const app = initializeApp(firebaseConfig);
