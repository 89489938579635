import "./Announcement.scss";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getPrices } from "../../Firebase/APIS";
// import {Price} from "../../utility/constant";



function Announcement() {
  const [prices, setPrices] = useState();
  
  async function getPricesList(){
    const resutl = await getPrices();
    if(resutl.success){
      setPrices(resutl.prices);
    }else{
      console.log(resutl.message);
    }
  }

  useEffect(() => {
    getPricesList()
  },[])

  return (
    <div className="announce-container">
      <div className="item-container">
          <div className="item">
          <p>
            Today: Gold 24k - 10g =
            <span> Rs. {prices?.gold_24_k_price}</span>
          </p>
        </div>
        <div className="item">
          <p>
            Today: Gold 22k - 10g ={" "}
            <span> Rs. {prices?.gold_22_k_price}</span>
          </p>
        </div>
        <div className="item">
          <p>
            Today: Silver - 1 Kg ={" "}
            <span> Rs. {prices?.silver_1_kg}</span>
          </p>
        </div>
      </div>
      <div className="socal">
        <a
          href="https://www.facebook.com/karimpurnewsunityjewellers"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF className="face-book" />
        </a>
        <a
          href=" https://www.instagram.com/newsunityjewellers"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram className="instagram" />
        </a>
      </div>
    </div>
  );
}

export default Announcement;
