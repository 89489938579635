import React, { useEffect, useState } from "react";
import "./BookingForm.scss";
import { useForm } from "react-hook-form";
import PageTopBanner from "../PageTopBanner/PageTopBanner";
import { useParams } from "react-router-dom";
import { ProductsData } from "../../utility/constant";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress } from "@mui/material";
import { ordersUser } from "../../Firebase/APIS";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BookingForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const { _id } = useParams();
  const { register, handleSubmit, reset } = useForm();

  const bookingMassage = async (data) => {
    setIsLoading(false);
    const orderData = {
      name: data.name,
      email: data.email,
      mobile: data.phone,
      address: data.address,
      productId: productDetails?._id,
    };
    const result = await ordersUser(orderData);
    if(result.success){
      reset();
    }
    alert(result.message);
    setIsLoading(true);
  };

  // const getProductDetails = () => {
  //   const cate = UseProductDetails(_id);
  //   cate
  //     .then((response) => {
  //       setProductDetails(response?.data);
  //       // console.log("response_data_products_Details:", response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // };

  const getProductDetails = () => {
    const product = ProductsData.filter((item) => item._id === _id);
    setProductDetails(product[0]);
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  if (productDetails.length === 0) {
    return <h1>Loading....</h1>;
  }
  return (
    <div className="booking-main">
      <PageTopBanner heading={"Book Now"} />
      <div className="booking-container">
        <div className="table">
          <div className="tableContainer">
            {productDetails ? (
              <div className="tableContainer">
                <div className="imgBox">
                  <img
                    src={productDetails?.image}
                    alt="image"
                    className="productimg"
                  />
                </div>
                {true ? (
                  <TableContainer sx={{ width: "100%" }} component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Product Details:</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow className="item-row">
                          <StyledTableCell scope="row">
                            Name : {productDetails?.name}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow className="item-row">
                          <StyledTableCell scope="row">
                            Weight : {productDetails?.weight} gm
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow className="item-row">
                          <StyledTableCell
                            scope="row"
                            className="category-name"
                          >
                            Category : {productDetails?.category}
                          </StyledTableCell>
                        </StyledTableRow>
                        {/* <StyledTableRow className="item-row">
                          <StyledTableCell scope="row">Slug : {productDetails?.data?.slug}</StyledTableCell>
                        </StyledTableRow> */}
                        <StyledTableRow className="item-row">
                          <StyledTableCell scope="row">
                            Description : {productDetails?.description}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <h3>Product Data Not Found!</h3>
                )}
              </div>
            ) : null}
          </div>
        </div>
        <div className="booking-form">
          <form onSubmit={handleSubmit(bookingMassage)} className="form">
            <h2>Book Your Jewellery</h2>
            <div className="group">
              <input
                type="text"
                placeholder="Name"
                name="name"
                id=""
                {...register("name", { required: true })}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                id=""
                {...register("email", { required: true })}
              />
            </div>
            <input
              type="text"
              name="phone"
              id=""
              placeholder="Phone Number"
              {...register("phone", { required: true })}
            />
            <textarea
              name="message"
              id=""
              cols="30"
              rows="5"
              placeholder="Address"
              {...register("address", { required: true })}
            ></textarea>
            <button type="submit">
              {isLoading ? (
                "Place Order"
              ) : (
                <CircularProgress size={20} color="info" />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookingForm;
