import React from "react";
import "./GalleryComponent.scss";
import img from "../../asset/Gallery/166A3755.webp";
import img2 from "../../asset/Gallery/166A3851.webp";
import img3 from "../../asset/Gallery/166A3856.webp";
import img4 from "../../asset/Gallery/166A3864.webp";
import img5 from "../../asset/Gallery/166A3865.webp";
import img6 from "../../asset/Gallery/166A3866.webp";
import img7 from "../../asset/Gallery/Mask Group-1.webp";
import img8 from "../../asset/Gallery/Mask Group-2.webp";
import img9 from "../../asset/Gallery/Mask Group-3.webp";
import img10 from "../../asset/Gallery/Mask Group.webp";
import img11 from "../../asset/Gallery/Untitled design (2).webp";
import img12 from "../../asset/Gallery/image 174.webp";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const GalleryComponent = () => {
  const images = [
    { img: img, alt: "Diamond Necklace and Earrings Set from New Sunity Jewellers" },
    { img: img3, alt: "New Sunity Jewellers Karimpur Store Staff Assisting Customers" },
    { img: img8, alt: "Exquisite Gold Necklaces at New Sunity Jewellers" },
    { img: img7, alt: "Precious Stone Rings Collection by New Sunity Jewellers" },
    { img: img11, alt: "New Sunity Jewellers Kolkata Custom Jewelry Designs" },
    { img: img10, alt: "Exquisite Gold Bangles Showcase by New Sunity Jewellers" },
    { img: img9, alt: "Exclusive Karimpur Jewelry Designs by New Sunity Jewellers" },
    { img: img6, alt: "Nadia Silver Jewelry with Gemstones from New Sunity Jewellers" },
    { img: img2, alt: "Kolkata Gold Chain Collection from New Sunity Jewellers" },
    { img: img4, alt: "Vintage Gold Jewelry Collection at New Sunity Jewellers" },
    { img: img5, alt: "Unique Diamond Brooch Designs by New Sunity Jewellers" },
    { img: img12, alt: "Model Wearing Exclusive Jewelry from New Sunity Jewellers" },
  ];
  return (
    <div>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {images.map((image, i) => (
            <img className="gallery-page-img"
              key={i}
              src={image.img}
              style={{ width: "100%", display: "block" }}
              alt={image.alt}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default GalleryComponent;
