import React from "react";
import "./VideoContainer.scss";
import video from "../../asset/video/video.mp4";
// import video from "../../asset/video/video2.mp4";
import { IoMdCloseCircle } from "react-icons/io";

const VideoContainer = ({setVideo}) => {
  
  const hideVideo = () => {
    setVideo(false)
  };
  return (
    <div className="video-container">
      <div className="video-box">
        <div className="close">
        <IoMdCloseCircle  onClick={hideVideo} />
        </div>
        <video autoPlay loop muted>
          <source src={video} />
        </video>
      </div>
    </div>
  );
};

export default VideoContainer;
