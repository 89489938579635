import React from "react";
import "./Cart.scss";
import CartComponent from "../../Components/CartComponent/CartComponent";
import { Helmet } from "react-helmet";
const Cart = () => {
  return (
    <div>
      <Helmet>
        <title>
          Welcome to New Sunity Jewellers - Explore Our Exquisite Jewelry
        </title>
        <meta
          name="description"
          content="Discover the finest collection of gold, diamond, and silver jewelry at New Sunity Jewellers. Explore exquisite designs and craftsmanship in Karimpur, Kolkata, Krishnanagar, and Nadia."
        />
        <meta
          name="keywords"
          content="New Sunity Jewellers, Jewelry Store, Gold Jewelry, Diamond Jewelry, Silver Jewelry, Karimpur, Kolkata, Krishnanagar, Nadia"
        />
      </Helmet>
      <CartComponent />
    </div>
  );
};

export default Cart;
