import axios from "axios";
import gBagle from "../asset/product/GOLD BANGLE.webp";
// import gBracelet from "../asset/product/GOLD BRACELET.webp";
import gChain1 from "../asset/product/GOLD CHAIN1.webp";
import gChain2 from "../asset/product/GOLD CHAIN 2.webp";
import gChain3 from "../asset/product/GOLD CHAIN 3.webp";
import gChain4 from "../asset/product/GOLD CHAIN 4.webp";
import gChain5 from "../asset/product/GOLD CHAIN 5.webp";
import gChain6 from "../asset/product/GOLD CHAIN6.webp";
import gChain7 from "../asset/product/GOLD CHAIN7.webp";
import gChain8 from "../asset/product/GOLD CHAIN 8.webp";
import gEarring from "../asset/product/GOLD EARRING.webp";
import gNacklace1 from "../asset/product/GOLD NECKLACE 1.webp";
import gNacklace2 from "../asset/product/GOLD NECKLACE 2.webp";
import gRingW from "../asset/product/GOLD RING WOMEN.webp";
import PolaBangle from "../asset/product/POLA BANGLE.webp";
import SankhaBangle from "../asset/product/SANKHA BANGLE.webp";
import SankhaBangle1 from "../asset/product/SANKHA BANGLE 1.webp";

// export const PRODUCTS_API_URL = "http://13.233.230.61:80/products?page=1&limit=10";
// export const API_URL = "https://api.newsunityjewellers.in";
export const API_URL = "https://sunity-jewellers-backend.onrender.com";
export const SEND_MAIL = `${API_URL}/api/user/contact-us`;
export const PLACED_ODER = `${API_URL}/api/user/placed-order`;
export const PRODUCTS = `${API_URL}/api/product/get-all-product?`;
export const PRODUCTS_DETAILS = `${API_URL}/api/product/get-product/`;
export const CATEGORY = `${API_URL}/api/category/get-all-category`;
export const PRICES = `${API_URL}/api/product/get-all-price`;

export const UsePrice = async () => {
  return axios.get(PRICES);
};

export const UseProduct = async (page, categoryDetails) => {
  const categorySend = {
    category: categoryDetails,
  };
  return axios.post(`${PRODUCTS}page=${page}&limit=8&`, categorySend);
};

export const UseCategorys = async () => {
  return axios.get(CATEGORY);
};

export const UseProductDetails = async (id) => {
  return axios.get(`${PRODUCTS_DETAILS}${id}`);
};

export const UseContact = async (data) => {
  return axios.post(SEND_MAIL, { ...data });
};

export const UseBooking = async (data, _id) => {
  return axios.post(API_URL + "/api/user/placed-order", {
    ...data,
    product_id: _id,
  });
};

export const eightProduct = [
  {
    _id: "65edafabd27422e7b7f2a95e",
    name: "GOLD NECKLACE",
    slug: "65583908-636e-40b7-8961-a375ee72da0c",
    image: gNacklace1,
    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075819367" } },
    updatedAt: { $date: { $numberLong: "1710075819367" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edafbbd27422e7b7f2a969",
    name: "GOLD NECKLACE",
    slug: "b4fbade1-a587-4cb2-a2ef-b68e5aba9395",
    image: gNacklace2,

    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075835619" } },
    updatedAt: { $date: { $numberLong: "1710075835619" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edafc3d27422e7b7f2a96e",
    name: "GOLD NECKLACE",
    slug: "e0fa1e5e-7582-4d33-ace8-ec10b70218e5",
    image: gNacklace1,
    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075843319" } },
    updatedAt: { $date: { $numberLong: "1710075843319" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edafcbd27422e7b7f2a973",
    name: "GOLD NECKLACE",
    slug: "4599b4e5-494f-4c3b-84e1-a6d9e4f824a5",
    image: gNacklace2,
    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075851707" } },
    updatedAt: { $date: { $numberLong: "1710075851707" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edb9fdd27422e7b7f2a991",
    name: "GOLD BALA",
    slug: "2b707799-dad7-4687-99bf-d2496c5a63f5",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bala",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078461355" } },
    updatedAt: { $date: { $numberLong: "1710078461355" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba14d27422e7b7f2a99a",
    name: "GOLD BALA",
    slug: "79369705-04dd-4ae2-9867-3fcf0326490f",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bala",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078484472" } },
    updatedAt: { $date: { $numberLong: "1710078484472" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba1dd27422e7b7f2a99f",
    name: "GOLD BALA",
    slug: "742e6e82-a8d2-41ea-9e9b-a07030f3c997",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bala",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078493704" } },
    updatedAt: { $date: { $numberLong: "1710078493704" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba3cd27422e7b7f2a9a9",
    name: "GOLD EARRING",
    slug: "81ffd706-2f48-4ce8-8f41-e37fd09b4de2",
    image: gEarring,
    weight: "0",
    description: "22k916",
    category: "gold earring",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078524017" } },
    updatedAt: { $date: { $numberLong: "1710078524017" } },
    __v: { $numberInt: "0" },
  },
];

export const CategorieData = [
  {
    _id: "65edadaad27422e7b7f2a6h",
    name: "all",
    createdAt: { $date: { $numberLong: "1710075306475" } },
    updatedAt: { $date: { $numberLong: "1710075306475" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edadaad27422e7b7f2a91e",

    name: "gold neklace",
    createdAt: { $date: { $numberLong: "1710075306475" } },
    updatedAt: { $date: { $numberLong: "1710075306475" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edaed9d27422e7b7f2a930",
    name: "gold chain",
    createdAt: { $date: { $numberLong: "1710075609099" } },
    updatedAt: { $date: { $numberLong: "1710075609099" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edaef1d27422e7b7f2a935",
    name: "sankha bengle",
    createdAt: { $date: { $numberLong: "1710075633130" } },
    updatedAt: { $date: { $numberLong: "1710075633130" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edaf3bd27422e7b7f2a93f",
    name: "pola bengle",
    createdAt: { $date: { $numberLong: "1710075707813" } },
    updatedAt: { $date: { $numberLong: "1710075707813" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edaf44d27422e7b7f2a944",
    name: "gold bala",
    createdAt: { $date: { $numberLong: "1710075716935" } },
    updatedAt: { $date: { $numberLong: "1710075716935" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edaf4bd27422e7b7f2a949",
    name: "gold ring",
    createdAt: { $date: { $numberLong: "1710075723979" } },
    updatedAt: { $date: { $numberLong: "1710075723979" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edaf5ad27422e7b7f2a94e",
    name: "gold earring",
    createdAt: { $date: { $numberLong: "1710075738050" } },
    updatedAt: { $date: { $numberLong: "1710075738050" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edaf67d27422e7b7f2a953",
    name: "gold bracelet",
    createdAt: { $date: { $numberLong: "1710075751011" } },
    updatedAt: { $date: { $numberLong: "1710075751011" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbc70d27422e7b7f2aad9",
    name: "gold bangle",
    createdAt: { $date: { $numberLong: "1710079088459" } },
    updatedAt: { $date: { $numberLong: "1710079088459" } },
    __v: { $numberInt: "0" },
  },
];

export const ProductsData = [
  {
    _id: "65edafabd27422e7b7f2a95e",
    name: "GOLD NECKLACE",
    slug: "65583908-636e-40b7-8961-a375ee72da0c",
    image: gNacklace1,
    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075819367" } },
    updatedAt: { $date: { $numberLong: "1710075819367" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edafbbd27422e7b7f2a969",
    name: "GOLD NECKLACE",
    slug: "b4fbade1-a587-4cb2-a2ef-b68e5aba9395",
    image: gNacklace2,

    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075835619" } },
    updatedAt: { $date: { $numberLong: "1710075835619" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edafc3d27422e7b7f2a96e",
    name: "GOLD NECKLACE",
    slug: "e0fa1e5e-7582-4d33-ace8-ec10b70218e5",
    image: gNacklace1,
    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075843319" } },
    updatedAt: { $date: { $numberLong: "1710075843319" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edafcbd27422e7b7f2a973",
    name: "GOLD NECKLACE",
    slug: "4599b4e5-494f-4c3b-84e1-a6d9e4f824a5",
    image: gNacklace2,
    weight: "0",
    description: "22k916",
    category: "gold neklace",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710075851707" } },
    updatedAt: { $date: { $numberLong: "1710075851707" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edb9fdd27422e7b7f2a991",
    name: "GOLD BALA",
    slug: "2b707799-dad7-4687-99bf-d2496c5a63f5",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bala",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078461355" } },
    updatedAt: { $date: { $numberLong: "1710078461355" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba14d27422e7b7f2a99a",
    name: "GOLD BALA",
    slug: "79369705-04dd-4ae2-9867-3fcf0326490f",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bala",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078484472" } },
    updatedAt: { $date: { $numberLong: "1710078484472" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba1dd27422e7b7f2a99f",
    name: "GOLD BALA",
    slug: "742e6e82-a8d2-41ea-9e9b-a07030f3c997",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bala",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078493704" } },
    updatedAt: { $date: { $numberLong: "1710078493704" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba35d27422e7b7f2a9a4",
    name: "GOLD EARRING",
    slug: "7f7fcd97-5d96-46dc-87c3-ae072eeddd0d",
    image: gEarring,
    weight: "0",
    description: "22k916",
    category: "gold earring",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078517255" } },
    updatedAt: { $date: { $numberLong: "1710078517255" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba3cd27422e7b7f2a9a9",
    name: "GOLD EARRING",
    slug: "81ffd706-2f48-4ce8-8f41-e37fd09b4de2",
    image: gEarring,
    weight: "0",
    description: "22k916",
    category: "gold earring",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078524017" } },
    updatedAt: { $date: { $numberLong: "1710078524017" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba46d27422e7b7f2a9ae",
    name: "GOLD EARRING",
    slug: "77ea46cf-0fef-4079-b59a-7f46a24c5dc2",
    image: gEarring,
    weight: "0",
    description: "22k916",
    category: "gold earring",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078534147" } },
    updatedAt: { $date: { $numberLong: "1710078534147" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba59d27422e7b7f2a9b3",
    name: "GOLD RING MEN",
    slug: "d9ba1cf6-8ad1-4aeb-a4bb-2637a6396976",
    image: gRingW,
    weight: "0",
    description: "22k916",
    category: "gold ring",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078553703" } },
    updatedAt: { $date: { $numberLong: "1710078553703" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba64d27422e7b7f2a9b8",
    name: "GOLD RING WOMEN",
    slug: "db0a561c-cc6b-4837-ab42-e8dcacaee06f",
    image: gRingW,
    weight: "0",
    description: "22k916",
    category: "gold ring",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078564950" } },
    updatedAt: { $date: { $numberLong: "1710078564950" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edba89d27422e7b7f2a9cd",
    name: "GOLD CHAIN",
    slug: "33bcdfb5-d7b7-4e65-be2e-174efee22ccf",
    image: gChain1,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078601920" } },
    updatedAt: { $date: { $numberLong: "1710078601920" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbaf3d27422e7b7f2aa1d",
    name: "GOLD CHAIN",
    slug: "2320fef9-d028-47fb-b933-fa35f3657dca",
    image: gChain2,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078707838" } },
    updatedAt: { $date: { $numberLong: "1710078707838" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbafad27422e7b7f2aa22",
    name: "GOLD CHAIN",
    slug: "b1e59b92-2a9c-4e73-9d3f-37a3a7ac5853",
    image: gChain3,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078714075" } },
    updatedAt: { $date: { $numberLong: "1710078714075" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb04d27422e7b7f2aa27",
    name: "GOLD CHAIN",
    slug: "cd9c42cd-d7ca-41c1-bb55-c0774604f852",
    image: gChain4,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078724102" } },
    updatedAt: { $date: { $numberLong: "1710078724102" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb08d27422e7b7f2aa2c",
    name: "GOLD CHAIN",
    slug: "e38991c0-faf8-4810-9281-fd1529c01e53",
    image: gChain5,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078728511" } },
    updatedAt: { $date: { $numberLong: "1710078728511" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb0ed27422e7b7f2aa31",
    name: "GOLD CHAIN",
    slug: "01a86ab1-93ed-4670-8a26-1159a5e16360",
    image: gChain6,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078734675" } },
    updatedAt: { $date: { $numberLong: "1710078734675" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb2cd27422e7b7f2aa43",
    name: "GOLD CHAIN",
    slug: "eaf08754-32b2-460d-ba9f-699ebdfb24ea",
    image: gChain7,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078764998" } },
    updatedAt: { $date: { $numberLong: "1710078764998" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb42d27422e7b7f2aa4e",
    name: "GOLD CHAIN",
    slug: "c1e0ee30-88a9-4147-a9e2-b65a3521e0bb",
    image: gChain8,
    weight: "0",
    description: "22k916",
    category: "gold chain",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078786186" } },
    updatedAt: { $date: { $numberLong: "1710078786186" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb68d27422e7b7f2aa53",
    name: "SANKHA BANGLE",
    slug: "6e6a6306-b938-44aa-a16a-2091311f9dff",
    image: SankhaBangle,
    weight: "0",
    description: "22k916",
    category: "sankha bengle",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078824800" } },
    updatedAt: { $date: { $numberLong: "1710078824800" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb70d27422e7b7f2aa58",
    name: "SANKHA BANGLE",
    slug: "eeec6cdf-74eb-44d7-bf52-dd38e1ee9057",
    image: SankhaBangle1,
    weight: "0",
    description: "22k916",
    category: "sankha bengle",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078832475" } },
    updatedAt: { $date: { $numberLong: "1710078832475" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb77d27422e7b7f2aa5d",
    name: "SANKHA BANGLE",
    slug: "23253922-b5a0-4c23-9296-fd360bfc1159",
    image: SankhaBangle,
    weight: "0",
    description: "22k916",
    category: "sankha bengle",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078839703" } },
    updatedAt: { $date: { $numberLong: "1710078839703" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbb97d27422e7b7f2aa62",
    name: "GOLD RING MEN",
    slug: "a3dac811-85f8-4176-bd8d-749ec6e1f200",
    image: gRingW,
    weight: "0",
    description: "22k916",
    category: "gold ring",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078871239" } },
    updatedAt: { $date: { $numberLong: "1710078871239" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbbd8d27422e7b7f2aa8a",
    name: "POLA BANGLE",
    slug: "82189f26-d8f1-424c-9eca-4f9e63bc7157",
    image: PolaBangle,
    weight: "0",
    description: "22k916",
    category: "pola bengle",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078936310" } },
    updatedAt: { $date: { $numberLong: "1710078936310" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbbe0d27422e7b7f2aa8f",
    name: "POLA BANGLE",
    slug: "08f7bb31-3bdb-42cc-b38b-193ebabaec4c",
    image: PolaBangle,
    weight: "0",
    description: "22k916",
    category: "pola bengle",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078944321" } },
    updatedAt: { $date: { $numberLong: "1710078944321" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbbe4d27422e7b7f2aa94",
    name: "POLA BANGLE",
    slug: "167e0319-6bba-4663-bc7a-176c64ebafc8",
    image: PolaBangle,
    weight: "0",
    description: "22k916",
    category: "pola bengle",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710078948665" } },
    updatedAt: { $date: { $numberLong: "1710078948665" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbc1ad27422e7b7f2aa99",
    name: "GOLD BRACELET",
    slug: "921e6ac6-cccd-4d4c-963d-f21b1e7cdcb4",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bracelet",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710079002498" } },
    updatedAt: { $date: { $numberLong: "1710079002498" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65edbc86d27422e7b7f2aadf",
    name: "GOLD BANGLE",
    slug: "ac57df96-f6a0-4be7-a21e-b88f11f4275b",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bangle",
    user: "65edab2063c4f6fe4353459f",
    createdAt: { $date: { $numberLong: "1710079110742" } },
    updatedAt: { $date: { $numberLong: "1710079110742" } },
    __v: { $numberInt: "0" },
  },
  {
    _id: "65ef2d64d27422e7b7f2ac80",
    name: "GOLD CHUR",
    slug: "9c5d3618-be30-428e-8fa5-1537961365a2",
    image: gBagle,
    weight: "0",
    description: "22k916",
    category: "gold bangle",
    user: "65ef2940d27422e7b7f2abeb",
    createdAt: { $date: { $numberLong: "1710173540117" } },
    updatedAt: { $date: { $numberLong: "1710173540117" } },
    __v: { $numberInt: "0" },
  },
];

export const Price = {
  _id: { $oid: "65f01ca94010fd94ec1f86d1" },
  gold_24_k_price: "74880",
  gold_22_k_price: "70150",
  silver_1_kg: "86382",
  updatedAt: { $date: { $numberLong: "1712466159904" } },
};
