import { useState } from "react";
import Announcement from "../Announcement/Announcement";
import "./AppBar.scss";
import logo from "../../asset/logo/logo-1.webp";
// import logo from "../../asset/Icon svg/NSJ BLACK WHITE JPG (2) 1.svg";
import { IoBagOutline } from "react-icons/io5";
import { RiMenu4Line } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { useCartContext } from "../../context/CartContext";

function AppBar() {
  const [showNavbar, setShowNavbar] = useState(false);

  const { cart  } = useCartContext();

  const toggleNav = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <div className="nav-container">
      <div className="logo">
        <Link to={"/"}>
          <img src={logo} alt="New Sunity Jewellers Logo" />
        </Link>
      </div>
      <div className="navbar-container">
        <div className="announcement">
          <Announcement />
        </div>
        <div className="nav-bar">
          <nav className={showNavbar ? "mb-nav-menu nav-menu" : "nav-menu"}>
            <ul className="nav-link">
              <NavLink to={"/"}>
                <li onClick={() => setShowNavbar(false)} className="home">
                  Home
                </li>
              </NavLink>
              <NavLink to={"/product"}>
                <li onClick={() => setShowNavbar(false)}>Products</li>
              </NavLink>
              <NavLink to={"/about"}>
                <li onClick={() => setShowNavbar(false)}>About</li>
              </NavLink>
              <NavLink to={"/contact"}>
                <li onClick={() => setShowNavbar(false)}>Contact</li>
              </NavLink>
              <NavLink to={"/gallery"}>
                {" "}
                <li onClick={() => setShowNavbar(false)}>gallery</li>
              </NavLink>
              <NavLink to={"/cart"}>
              {/* <Link to={"/"}> */}
                {" "}
                <li onClick={() => setShowNavbar(false)} className="cart">
                  {" "}
                  <IoBagOutline />{" "}
                  <p
                    className={
                      cart.length !== 0 ? "cart-item-number" : "hidden"
                    }
                  >
                    {cart.length}
                  </p>
                </li>
              {/* </Link> */}
              </NavLink>
            </ul>
          </nav>
          <div className="box bg-yellow mb-bar" onClick={toggleNav}>
            {showNavbar ? <MdOutlineClose /> : <RiMenu4Line />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppBar;
