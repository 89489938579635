import React from "react";
import { IoBagOutline } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
import "./ProductCard.scss";
import { Link } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";


const ProductCard = ({ item }) => {
 const {addToCart} = useCartContext();

  const add = () =>{
    addToCart(item);
  }

  // console.log(item);

  const { name, description, _id, image ,weight  } = item;
  // console.log(image);
  return (
    <div className="product-card" key={_id}>
      <img src={ image} alt={name} />
      <div className="details">
        <h3 className="product-heading">{name}</h3>
        <p className="product-sub">{description}</p>
        {/* <p>{weight} gm</p> */}
        <div className="btn">
          <IoBagOutline className="add-cart button" onClick={add} />
          <Link to={"/booking/" + _id}><MdOutlineShoppingCart className="book-now button"/></Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
