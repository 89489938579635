import React from "react";
import "./Footer.scss";
import { FaFacebookF, FaInstagramSquare, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-contact">
          <div className="address box">
            <h4>Address</h4>
            <p>
              NEW SUNITY JEWELLERS, HATGHOR, SURENDRA MARKET, KARIMPUR NADIA
              WEST BENGAL, 741152
            </p>
          </div>
          <div className="call box">
            <h4>Call</h4>
            <a href="tel:+918001428838">+91 8001428838</a>
            <br />
            <a href="tel:+918001428829">+91 8001428829</a>
            <br />
            <a href="tel:+919775634841">+91 9775634841</a>
          </div>
          <div className="email box">
            <h4>Email</h4>
            <a href="mailto: info@newsunityjewellers.in">
              info@newsunityjewellers.in
            </a>
            <br />
            <a href="mailto: SUPPORT@NEWSUNITYJEWELLERS.IN">
            SUPPORT@NEWSUNITYJEWELLERS.IN
            </a>
          </div>
        </div>
        <div className="footer-Links">
          <div className="socal-link">
            <a href="https://www.facebook.com/karimpurnewsunityjewellers" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className="fb icon" />
            </a>
            <a href="https://maps.app.goo.gl/vd4PamPr9nDsUJgSA" target="_blank" rel="noopener noreferrer">
              <FaMapMarkerAlt className="tw icon" />
            </a>
            <a href=" https://www.instagram.com/newsunityjewellers" target="_blank" rel="noopener noreferrer">
              <FaInstagramSquare className="insta icon" />
            </a>
          </div>
          <div className="link">
            <Link to={"/"}>
              <li>Home </li>
            </Link>
            <Link to={"/about"}>
              <li>About Us</li>
            </Link>
            <Link to={"/product"}>
              <li> Our Collection </li>
            </Link>
            <Link to={"/product"}>
              <li> Our Products </li>
            </Link>
            <Link to={"/gallery"}>
              <li> Gallery</li>
            </Link>
            <Link to={"/contact"}>
              <li>Contact</li>
            </Link>

            {/* <li>Origin </li> */}
            {/* <li>Production</li> */}
            {/* <li>Fairs/Events</li> */}
            {/* <li>News / Blog </li> */}

            {/* <li>Imprint</li> */}
          </div>
          <div className="copy-right">
            <p>
              © New Sunity Jweller’s {currentYear} . <span>All Rights Reserved.</span>
            </p>
            <br />
            <p>
              <a href="https://aspireworldtechsolutions.com/" target="_blank" rel="noopener noreferrer">
              &copy;  Created By - Aspire World Tech Solutions
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
