import React, { useState, useEffect } from "react";
import "./Carosuel.scss";
import img from "../../asset/Hero/hero1.webp";
import img2 from "../../asset/Hero/hero2.webp";
import img3 from "../../asset/Hero/hero3.webp";
import img4 from "../../asset/Hero/hero4.webp";
import imgg from "../../asset/Hero/heroo.webp";
import CarouselCard from "../CarouselCard/CarouselCard";
const Carosuel = () => {
  const [slide, setSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (slide < 4) {
        setSlide(slide + 1);
      } else {
        setSlide(0);
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [slide]);
  const carouselItem = [
    {
      heading: " Welcome to New Sunity Jewellers",
      pra: "Where Every Gem Tells a Story",
      img: imgg,
      num: 1,
      alt :"New Sunity Jewellers Karimpur Store Exterior"
    },
    {
      heading: "UNVEILING SPLENDOR IN EVERY DETAIL",
      pra: "EXPERIENCE LUXURY WITH OUR FINEST JEWELLERY COLLECTION",
      img: img2,
      num: 2,
      alt : "New Sunity Jewellers Kolkata Showroom Interior"
    },
    {
      heading: "WHERE BEAUTY MEETS BRILLIANCE",
      pra: "INDULGE IN THE UNMATCHED CRAFTSMANSHIP OF OUR JEWELLERY",
      img: img,
      num: 3,
      alt : "Exquisite Gold Necklaces at New Sunity Jewellers"
    },
    {
      heading: "SPARKLE WITH STYLE",
      pra: "PRECIOUS JEWELS THAT CAPTURE HEARTS",
      img: img4,
      num: 4,
      alt : "Gold and Diamond Jewelry Combination at New Sunity Jewellers "
    },
    {
      heading: "BEYOND BEAUTY",
      pra: "WHERE PRECISION MEETS PASSION",
      img: img3,
      num: 5,
      alt :"Diamond Rings Collection at New Sunity Jewellers"
    },
  ];
  return (
    <div className="carousel-contaer">
      <div className="carousel">
        <div className="main">
          {carouselItem.map((item, index) => {
            return (
              <CarouselCard
                item={item}
                key={item.num}
                index={index}
                slide={slide}
              />
            );
          })}
          <div className="indicators">
            {carouselItem.map((item, index) => {
              return (
                <p
                  key={item.num}
                  onClick={() => setSlide(index)}
                  className={slide === index ? "indicator active" : "indicator"}
                >
                  {item.num}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carosuel;
