import React from "react";
import GalleryPageComponent from "../PagesComponent/Gallery/GalleryPageComponent";
import { Helmet } from "react-helmet";

const GalleryPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Explore the Exquisite Jewelry Gallery - New Sunity Jewellers
        </title>
        <meta
          name="description"
          content="Browse through the stunning jewelry gallery at New Sunity Jewellers. Explore our exquisite collection of gold, diamond, and silver jewelry showcased with elegance."
        />
        <meta
          name="keywords"
          content="New Sunity Jewellers, Jewelry Gallery, Exquisite Jewelry, Gold Jewelry, Diamond Jewelry, Silver Jewelry"
        />
      </Helmet>
      <div style={{ display: "none" }}>
        <h1>
          Indulge in Luxury - New Sunity Jewellers' Holmark Jewelry Selection
        </h1>
        <h1>Holmark Jewelry Extravaganza - New Sunity Jewellers, Karimpur</h1>
        <h1>
          New Sunity Jewellers - Your Path to Holmark Elegance in Karimpur
        </h1>
        <h1>Discover Karimpur's Jewelry Gem - New Sunity Jewellers</h1>
        <h1>Adorn Yourself in Holmark - Available at New Sunity Jewellers</h1>
        <h1>New Sunity Jewellers - Your Destination for Holmark Brilliance</h1>
        <h1>
          Explore Karimpur's Finest - Holmark Collection at New Sunity Jewellers
        </h1>
        <h1>New Sunity Jewellers - Unveiling the Allure of Holmark Jewelry</h1>
        <h1>
          Step into Opulence - New Sunity Jewellers, Karimpur's Premier Jewelry
          Store
        </h1>
        <h1>
          Holmark Jewelry at Its Finest - New Sunity Jewellers in Karimpur
        </h1>
        <h1>
          New Sunity Jewellers - Your Gateway to Holmark's Dazzling Creations
        </h1>
        {/* <!-- Internal Link → */}
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/product"
          target="_blank"
        >
          Product Page
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/about"
          target="_blank"
        >
          About Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/contact"
          target="_blank"
        >
          Contact Us
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/gallery"
          target="_blank"
        >
          View Gallery
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/cart"
          target="_blank"
        >
          View Cart
        </a>
        <a
          rel="noopener noreferrer"
          href="https://newsunityjewellers.in/"
          target="_blank"
        >
          Home
        </a>
        {/* <!-- External Links → */}
        <a
          rel="noopener noreferrer"
          href="https://aspireworldtechsolutions.com/"
          target="_blank"
        >
          Aspire World Tech Solutions
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.energiccropscience.com/"
          target="_blank"
        >
          Energic Crop Science
        </a>
        <a
          rel="noopener noreferrer"
          href="https://sudhirbastralaya.in/"
          target="_blank"
        >
          Sudhir Astralaya
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.sunitijewellers.com/"
          target="_blank"
        >
          Suniti Jewellers
        </a>
      </div>

      <GalleryPageComponent />
    </div>
  );
};

export default GalleryPage;
