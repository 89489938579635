import React from "react";
import "./Gallery.scss";
import img from "../../asset/New Sunity Jweller’s_Image/Mask Group.webp";
import img1 from "../../asset/New Sunity Jweller’s_Image/Mask Group-3.webp";
import img2 from "../../asset/New Sunity Jweller’s_Image/Mask Group-1.webp";
import img3 from "../../asset/New Sunity Jweller’s_Image/Mask Group-2.webp";
import instagram from "../../asset/New Sunity Jweller’s_Image/image 185.webp";
import Morebutton from "../MoreButton/Morebutton";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <div id="gallery" className="gallery section">
      <div className="gallery-text">
        <h1>View our gallery</h1>
        <Link to={"gallery"}>
          <Morebutton more={"View more"} />
        </Link>
      </div>
      <div className="gallery-container">
        <img src={img} alt="Exclusive Gold Bracelets at New Sunity Jewellers" />
        <img src={img1} alt="Fine Diamond Jewelry Pieces by New Sunity Jewellers" />
        <img src={img2} alt="Precious Stone Rings Collection by New Sunity Jewellers" />
        <img src={img3} alt="Diamond Necklace and Earrings Set from New Sunity Jewellers" />
        <a
          href="https://www.instagram.com/newsunityjewellers"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="instagram">
            <img src={instagram} alt="Instagram logo" />
            <p>Instagram</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Gallery;
