import React from "react";
import "./CarouselCard.scss";
import { IoMdArrowDropright } from "react-icons/io";

const CarouselCard = ({ item, slide, index }) => {
  // console.log(item);
  const { heading, pra, img, alt } = item;
  return (
    <div className="slider">
      <div className={slide === index ? "bg-img" : "bg-img bg-hidden"}>
        <img src={img} alt={alt} />
        <p className="Mirzazadeh">
          <span>Mirzazadeh</span> – Quality For Life”
        </p>
        <div className="learn--container">
          <a href="#products">
            <p className="learn-more">
              learn more <IoMdArrowDropright className="arrow" />
            </p>
            <div className="dash"></div>
          </a>
        </div>
        <div className="title">
          <h1 className="heading">{heading}</h1>
          <p className="pra">{pra}</p>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
