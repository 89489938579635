import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AboutPage from "./Pages/AboutPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import BookingPage from "./Pages/BookingPage";
import CartPage from "./Pages/CartPage";
import ProductPage from "./Pages/ProductPage";
import ProductDetails from "./PagesComponent/ProductDetails/ProductDetails";
import HomePage from "./Pages/HomePage";
import ContactPage from "./Pages/ContactPage";
import GalleryPage from "./Pages/GalleryPage";
import PageNotFoundPage from "./Pages/PageNotFoundPage";
// import VideoContainer from './Components/VideoContainer/VideoContainer';

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement : <PageNotFoundPage/> ,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/booking/:_id",
        element: <BookingPage />,
      },
      {
        path: "/cart",
        element: <CartPage />,
      },
      {
        path: "/product",
        element: <ProductPage />,
      },
      {
        path: "/product-details",
        element: <ProductDetails />,
      },
      {
        path: "/gallery",
        element: <GalleryPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div className="App">
      <RouterProvider router={appRouter} />
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
