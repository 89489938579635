import { getDatabase, ref, get, set, push } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

import { app } from "./firebase";
import { v4 as uuidv4 } from "uuid";
function generateId() {
  return uuidv4();
}
function currentTimestamp() {
  return Date.now();
}

export async function getCategory() {
  const database = getDatabase(app);
  const categoryRef = ref(database, "sunity-jewellers/categorys");
  try {
    const categorySnapshot = await get(categoryRef);
    if (categorySnapshot.exists()) {
      const categories = categorySnapshot.val();
      return { success: true, categories: categories };
    } else {
      return { success: false, message: "No categories found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function getPrices() {
  const database = getDatabase(app);
  const pricesRef = ref(database, "sunity-jewellers/prices");

  try {
    const pricesSnapshot = await get(pricesRef);
    if (pricesSnapshot.exists()) {
      const pricesData = pricesSnapshot.val();
      return { success: true, prices: pricesData };
    } else {
      return { success: false, message: "No prices found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function ordersUser(orderData) {
  const database = getDatabase(app);
  const orderRef = ref(database, "sunity-jewellers/orders");
  try {
    const snapshot = await get(orderRef);

    const newOrder = {
      id: generateId(),
      name: orderData.name,
      email: orderData.email,
      mobile: orderData.mobile,
      address: orderData.address,
      productId: orderData.productId,
      status: true,
      timestamp: currentTimestamp(),
    };

    if (snapshot.exists()) {
      const users = snapshot.val();
      const usersArray = Array.isArray(users) ? users : Object.values(users);
      usersArray.push(newOrder);
      await set(orderRef, usersArray);
    } else {
      const usersArray = [newOrder];
      await set(orderRef, usersArray);
    }

    return {
      success: true,
      message: "Order placed successfully",
      orderId: newOrder.id,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export async function contactUser(userData) {
  const database = getDatabase(app);
  const contactRef = ref(database, "sunity-jewellers/contacts");
  try {
    const snapshot = await get(contactRef);

    const newContact = {
      id: generateId(),
      name: userData.name,
      email: userData.email,
      mobile: userData.mobile,
      message: userData.message,
      status: true,
      timestamp: currentTimestamp(),
    };

    if (snapshot.exists()) {
      const users = snapshot.val();
      const usersArray = Array.isArray(users) ? users : Object.values(users);
      usersArray.push(newContact);
      await set(contactRef, usersArray);
    } else {
      const usersArray = [newContact];
      await set(contactRef, usersArray);
    }

    return {
      success: true,
      message: "Message send successfully",
      orderId: newContact.id,
    };
  } catch (error) {
    return { success: false, message: error.message };
  }
}

// export async function getProduct() {
//   const database = getDatabase(app);
//   const productsRef = ref(database, "sunity-jewellers/products");
//   try {
//     const productsSnapshot = await get(productsRef);
//     if (productsSnapshot.exists()) {
//       const products = productsSnapshot.val();
//       return { success: true, products: products };
//     } else {
//       return { success: false, message: "No categories found" };
//     }
//   } catch (error) {
//     return { success: false, message: error.message };
//   }
// }

// export async function getSingleProduct(productId) {
//   const database = getDatabase(app);
//   const productsRef = ref(database, "sunity-jewellers/products");

//   try {
//     const productsSnapshot = await get(productsRef);
//     if (productsSnapshot.exists()) {
//       const productsObj = productsSnapshot.val();
//       const productsArray = Object.values(productsObj);
//       const product = productsArray.find((product) => product.id === productId);

//       if (product) {
//         return { success: true, product };
//       } else {
//         return { success: false, message: "Product not found" };
//       }
//     } else {
//       return { success: false, message: "No products found" };
//     }
//   } catch (error) {
//     return { success: false, message: error.message };
//   }
// }



// ====================user=====================

// export async function registerUser(userData) {
//   const database = getDatabase(app);
//   const usersRef = ref(database, "sunity-jewellers/users");
//   try {
//     const snapshot = await get(usersRef);

//     if (snapshot.exists()) {
//       const users = snapshot.val();

//       const existingUserEmail = Object.values(users).find(
//         (user) => user.email === userData.email
//       );
//       const existingUserMobile = Object.values(users).find(
//         (user) => user.mobile === userData.mobile
//       );

//       if (existingUserEmail || existingUserMobile) {
//         return {
//           success: false,
//           message: "User already exists",
//         };
//       }
//     }

//     const newUser = {
//       id: generateId(),
//       name: userData.name,
//       email: userData.email,
//       mobile: userData.mobile,
//       password: userData.password,
//       address: userData.address,
//       is_active: true,
//       timestamp: currentTimestamp(),
//     };

//     if (snapshot.exists()) {
//       const users = snapshot.val();
//       const usersArray = Array.isArray(users) ? users : Object.values(users);
//       usersArray.push(newUser);
//       await set(usersRef, usersArray);
//     } else {
//       const usersArray = [newUser];
//       await set(usersRef, usersArray);
//     }

//     return {
//       success: true,
//       message: "Registered successfully",
//       token: newUser.id,
//     };
//   } catch (error) {
//     return { success: false, message: error.message };
//   }
// }

// export async function loginUser({ email, mobile, password }) {
//   const database = getDatabase(app);
//   const usersRef = ref(database, "sunity-jewellers/users");

//   try {
//     const snapshot = await get(usersRef);

//     if (snapshot.exists()) {
//       const users = snapshot.val();
//       const usersArray = Array.isArray(users) ? users : Object.values(users);

//       const existingUser = usersArray.find(
//         (user) =>
//           (user.email === email || user.mobile === mobile) &&
//           user.password === password
//       );

//       if (existingUser) {
//         return {
//           success: true,
//           message: "Login successful",
//           token: existingUser.id,
//         };
//       } else {
//         return {
//           success: false,
//           message: "Invalid email/mobile or password",
//         };
//       }
//     } else {
//       return {
//         success: false,
//         message: "No users found",
//       };
//     }
//   } catch (error) {
//     return { success: false, message: error.message };
//   }
// }

// const AuthToken = "AuthToken";
// export const getAuthToken = () => {
//   const localLog = localStorage.getItem(AuthToken);
//   if (!localLog) {
//     return { success: false, message: "No token found" };
//   } else {
//     return { success: true, token: localLog };
//   }
// };

// export const setAuthToken = (token) => {
//   localStorage.setItem(AuthToken, token);
//   return { success: true, message: "Login successfully" };
// };

// export const removeAdminToken = async () => {
//   localStorage.removeItem(AuthToken);
//   return { success: true, message: "Logged out successfully" };
// };

// const userData = {
//   name: "John Doe",
//   email: "johnoe@example.com",
//   mobile: "1234567890",
//   password: "password123",
//   address: "123 Main St",
// };

// const logData = {
//   email: "johnoe@example.com",
//   password: "passwod123",
// };
