import React, { useEffect, useState } from "react";
import "./Product.scss";
import ProductCard from "../../Components/ProductCard/ProductCard";
import Pagination from "@mui/material/Pagination";
import Loader from "../../Components/Loader/Loader";
import {
  UseCategorys,
  UseProduct,
  ProductsData,
  CategorieData,
} from "../../utility/constant";

const Product = () => {
  const [page, setpage] = useState(1);
  const [categoryDetails, setCategoryDetails] = useState([]);
  const [categoryName, setCategoryName] = useState("all");
  const [products, setProducts] = useState(ProductsData);
  const [productsStore, setProductsStore] = useState(ProductsData);
  const [category, setCategory] = useState(CategorieData);

  // const getCategory = () => {
  //   const cate = UseCategorys();
  //   cate
  //     .then((response) => {
  //       setCategory(response?.data?.data);
  //       // console.log("response_data_products:", response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // };

  // const getProducts = () => {
  //   const temps = UseProduct(page, categoryDetails)
  //     .then((response) => {
  //       setProducts(response?.data);
  //       // console.log("response_data_products:", response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // };
  // useEffect(() => {
  //   getProducts();
  //   getCategory();
  // }, [categoryDetails, page]);
  useEffect(() => {
    if (categoryName !== "all") {
      const finterProductData = productsStore.filter(
        (item) => item.category === categoryName
      );
      setProducts(finterProductData);
      // console.log(finterProductData);
    }
    else if (categoryName === "all"){
      setProducts(productsStore);
    }
  }, [categoryName]);

  const selectCatrgory = (item) => {
    setCategoryName(item.name);

    // setCategoryDetails([item.id]);
  };
  // for api
  // const selectCatrgoryAll = () => {
  //   setCategoryDetails([]);
  //   setCategoryName(" ");
  // };
  const paginationHandel = (e, p) => {
    setpage(p);
  };

  // console.log("after ", products);
  return (
    <div className="all-product-container">
      <div className="heading-container">
        <h1 className="heading">Trending Product</h1>
        <div className="catagories">
          <div className="flex">
            {/* for api  */}
            {/* <li
              className={categoryName === " " && "active"}
              onClick={selectCatrgoryAll}
            >
              All
            </li> */}
            {category.map((item, index) => {
              return (
                <li
                  className={categoryName === item.name ? "active" : " "}
                  onClick={() => selectCatrgory(item)}
                  key={index}
                >
                  {item.name}
                </li>
              );
            })}
          </div>
        </div>
      </div>
      {products?.length !== 0 && (
        <div className="product-container">
          {/* for api  */}
          {/* {products?.product?.length !== 0 ? (
            products.product.map((item) => {
              return <ProductCard item={item} key={item._id} />;
            })
          ) : (
            <div className="no-product-container">
              <Loader />
              <p className="no-product">
                No product here! Choose another category
              </p>
            </div>
          )} */}

          {products.map((item, index) => {
            return <ProductCard item={item} key={index} />;
          })}
        </div>
      )}
      {products?.length === 0 && (
        <div className="page-btn-container">
          {products?.product?.length !== 0 && (
            <Pagination
              count={products?.pagination?.totalPage}
              size="large"
              color="warning"
              onChange={paginationHandel}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Product;
