import React from "react";
import "./PageTopBanner.scss";
import img from "../../asset/New Sunity Jweller’s_Image/Group 1000001250.webp"

const PageTopBanner = ({heading}) => {
  return (
    <div className="contact-bannar">
    <img src={img} alt="" />
      <h1>{heading}</h1>
    </div>
  );
};

export default PageTopBanner;
