import React, { useState } from "react";
import "./Contact.scss";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { useForm } from "react-hook-form";
import PageTopBanner from "../PageTopBanner/PageTopBanner";
import { CircularProgress } from "@mui/material";
import { contactUser } from "../../Firebase/APIS";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, reset } = useForm();
  const massage = async (data) => {
    setIsLoading(false);
    const result = await contactUser(data);
    if (result.success) {
      reset();
    }
    setIsLoading(true);
    alert(result.message);
  };
  return (
    <div className="contact-page">
      <PageTopBanner heading={"Let us know what you need!"} />
      <div className="contact">
        <div className="left">
          <h3>Contact</h3>
          <h1>Get In Touch</h1>
          <p>
            Leave your name, email, number and message we'll get right back to
            you.
          </p>
          <div className="box">
            <FaLocationDot className="icon" />
            <div className="box-content">
              <p>Location</p>
              <p>
                NEW SUNITY JEWELLERS, HATGHOR, SURENDRA MARKET, KARIMPUR NADIA
                WEST BENGAL, 741152
              </p>
            </div>
          </div>
          <div className="box">
            <IoCall className="icon" />
            <div className="box-content">
              <p>Call</p>
              <a href="tel:+918001428829" className="phone-number">
                +91 8001428829
              </a>
              <a href="tel:+918001428838" className="phone-number">
                +91 8001428838
              </a>
              <a href="tel:+919775634841" className="phone-number">
                +91 9775634841
              </a>
            </div>
          </div>
          <div className="box">
            <MdEmail className="icon" />
            <div className="box-content">
              <p>Email</p>
              <p>
                <a href="mailto: support@newsunityjewellers.in">
                  support@newsunityjewellers.in
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="right">
          <h2>Let's Connect</h2>
          <form onSubmit={handleSubmit(massage)} className="form">
            <div className="group">
              <input
                type="text"
                placeholder="Name"
                name="name"
                id=""
                {...register("name", { required: true })}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                id=""
                {...register("email", { required: true })}
              />
            </div>
            <input
              type="text"
              name="phone"
              id=""
              placeholder="Phone Number"
              {...register("mobile", { required: true })}
            />
            <textarea
              name="message"
              id=""
              cols="30"
              rows="5"
              placeholder="Massage"
              {...register("message", { required: true })}
            ></textarea>
            <button type="submit">
              {isLoading ? (
                "Send message"
              ) : (
                <CircularProgress size={20} color="info" />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
