import { Outlet, useNavigate } from "react-router-dom";
import "./App.scss";
import AppBar from "./Components/Navbar/AppBar";
import Footer from "./Components/Footer/Footer";
import { useEffect } from "react";
import qsane from "qsane";
import { CartProvider } from "./context/CartContext";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    if (qsane.getLocation().pathname === "/") {
      navigate("/");
    } else {
      navigate(qsane.getLocation().pathname);
    }
  }, []);
  return (
    <>
      <CartProvider>
        <AppBar />
        <div className="main-home-page">
          <Outlet />
        </div>
        <Footer />
      </CartProvider>
    </>
  );
}

export default App;
