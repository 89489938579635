import "./Home.scss";
import Carosuel from "../../Components/Carosusel/Carosuel";
import OurCollection from "../../Components/OurCollection/OurCollection";
import SaleAndSpace from "../../Components/Sale&Space/SaleAndSpace";
import Banner from "../../Components/Banner/Banner";
import AboutCompany from "../../Components/AboutCompany/AboutCompany";
import Gallery from "../../Components/Gallery/Gallery";
import Products from "../../Components/Products/Products";

function Home() {
  return (
    <div className="home-page">
      <Carosuel />
      <Products />
      <OurCollection />
      <SaleAndSpace />
      <Banner />
      <AboutCompany />
      <Gallery />
    </div>
  );
}

export default Home;
