import React from 'react'
import "./Booking.scss"
import BookingForm from '../../Components/BookingForm/BookingForm'
const Booking = () => {
  return (
    <div>
      <BookingForm/>
    </div>
  )
}

export default Booking
