
import AboutUsComponent from "../../Components/AboutUsComponent/AboutUsComponent";
import PageTopBanner from "../../Components/PageTopBanner/PageTopBanner";
import "./About.scss";

function About() {
  return (
    <div className="about-page">
      
      {/* <h1 className='heading-about'>About - Us</h1> */}
      <PageTopBanner heading={"About Us"} />
      <AboutUsComponent />
    </div>
  );
}

export default About;
